/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_appsync_graphqlEndpoint": "https://bhefpg5kfbcq7jyybh675fledq.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-7vx55k5vcjdvbmy7hrcqcct7wy",
    "aws_cloud_logic_custom": [
        {
            "name": "restApi",
            "endpoint": "https://k4dd6503pf.execute-api.ap-south-1.amazonaws.com/prod",
            "region": "ap-south-1"
        }
    ],
    "aws_cognito_identity_pool_id": "ap-south-1:6bcef895-4939-4e7a-b2ad-773845ae4df3",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_pNTZrgIWS",
    "aws_user_pools_web_client_id": "6da6i3o3aubht02grrucvtuit6",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "PHONE_NUMBER"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "SMS",
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "PHONE_NUMBER"
    ],
    "aws_user_files_s3_bucket": "buckler-d04add60153116-prod",
    "aws_user_files_s3_bucket_region": "ap-south-1"
};


export default awsmobile;
