import React, { useState } from 'react'
import FormControl from '../../../components/formControl'
import * as Yup from 'yup'
import { Formik } from 'formik'
import Spinner from '../../../components/spinner'
import { Link, useNavigate } from 'react-router-dom'
import { API } from 'aws-amplify'
import { createSocietyUser } from '../../../graphql/mutations'
import { toast } from 'react-toastify'
import { customSearchUsers } from '../../../custumQueries/custumQueries'
import { useSelector } from 'react-redux'

function CreateSocietyUser() {
    const userRole = require('../../../data/societyUserRole.json')
    const society = useSelector((store) => store.user_society)
    const navigate = useNavigate()
    const [spinner, showSpinner] = useState(false)
    const formData = {
        phone: ''
    }

    const formSchema = Yup.object().shape({
        phone: Yup.string().required('Required'),
    })


    const handleAddSociety = async (form) => {
        try {
            const userData = await API.graphql({
                query: customSearchUsers,
                variables: {
                    filter: { phone: { eq: `+91${form.phone}` } }
                }
            })
            if (userData.data.searchUsers.items[0] && !userData.data.searchUsers.items[0].societies.items?.some((x) => x.societyId === society.id && x.role === form.role.label)) {
                await API.graphql({
                    query: createSocietyUser,
                    variables: {
                        input: {
                            name: form.name,
                            societyId: society.id,
                            userId: userData.data.searchUsers.items[0].id,
                            role: form.role.label,
                        }
                    }
                }).then(res => {
                    showSpinner(true)
                    setTimeout(() => {
                        navigate(`/society-staff`)
                        toast.success('User added successfully')
                        showSpinner(false)
                    }, 3000)
                })
            }
            else {
                toast.warning('This user already exist in this society')
            }
        } catch (err) {
            console.log(err)
        }
    }


    return (
        <section>
            <div className='container'>
                <div className='row justify-content-center'>
                    <div className='col-12 col-lg-10 col-xl-8'>
                        <div className='header mt-md-5'>
                            <div className='header-body'>
                                <div className='row align-items-center'>
                                    <div className='col'>
                                        <h1 className='header-title'>
                                            Add User
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Formik initialValues={formData} validationSchema={formSchema} onSubmit={handleAddSociety} validateOnChange={false} validateOnBlur={false}>
                            {({ handleChange, handleSubmit, setFieldValue, values, touched, errors }) => {
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <FormControl type="phone" title="Phone" name='phone' placeholder={'Phone number'} value={values.phone} error={errors.phone} onChange={handleChange} required={true} />

                                        <FormControl type='select' name='role' title='Select Role' options={userRole} required={true}
                                            value={values.role} error={errors.role} onChange={handleChange} />
                                        <div>
                                            <Spinner show={spinner}>
                                                <button className="btn w-100 btn-primary" type='submit'>Add User</button>
                                            </Spinner>
                                            <Link to={`/society-staff`}>
                                                <div className='btn w-100 btn-link text-muted mt-2' type='button'>
                                                    Cancel
                                                </div>
                                            </Link>
                                        </div>
                                    </form>
                                )
                            }}
                        </Formik>
                        <div className='text-muted text-start' type='button'>
                            New User? <Link to={`/create/society-staff`}>Click here</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CreateSocietyUser
