import { Formik } from 'formik';
import React from 'react'
import * as Yup from 'yup'
import FormControl from '../../../components/formControl';
import Spinner from '../../../components/spinner';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import moment from "moment";
import ReactDatePicker from 'react-datepicker';
import { API } from 'aws-amplify';
import { createAmenity } from '../../../graphql/mutations';
import { toast } from 'react-toastify';
import axios from 'axios';
import NumberFormat from 'react-number-format';

function CreateFacilityType() {
    const navigate = useNavigate()
    const zohoId = useSelector((state) => state.zohoId)
    const society = useSelector((state) => state.user_society)

    const [duration, setDuration] = useState()
    const [selectedStartTime, setSelectedStartTime] = useState(null)
    const [selectedEndTime, setSelectedEndTime] = useState(null);
    const [spinner, showSpinner] = useState()
    const formData = {
        facility_type: '',
        paid: '',
        start_time: '',
        end_time: '',
        slot_duration: '',
        min_slot_duration: '',
        books_per_slot: '',
        allow_to_pay: '',
    }

    const formSchema = Yup.object().shape({
        facility_type: Yup.string().required('Required.'),
        paid: Yup.number().when("allow_to_pay", (allow_to_pay) => {
            if (allow_to_pay) {
                return Yup.number().required('Required.')
            }
        }),
        books_per_slot: Yup.number().required('Required.')
    })

    const handleCnacleCreate = () => {
        navigate('/facility')
    }

    const handleCreatefacility = async (form) => {
        showSpinner(true)
        const start_time = new Date(selectedStartTime)
        const end_time = new Date(selectedEndTime)
        let finalEndTime = moment(end_time, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').utc()
        let finalStartTime = moment(start_time, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ').utc()
        let slots = JSON.stringify({ slotStartTime: finalStartTime, slotEndTime: finalEndTime, slotDuration: form.duration.value.split(' ')[0], bookingCountPerSlot: form.books_per_slot })
        let data = JSON.stringify({
            name: form.facility_type,
            rate: form.allow_to_pay ? form.paid : 0,
        })
        axios.post(`https://m4ppic541a.execute-api.ap-south-1.amazonaws.com/dev/items?organization_id=${zohoId}`, data,
            {
                headers: {
                    'x-api-key': 'bxDJCXmPRk92r7bIdc3Kh8PwAZeCMeFb4Yv0BU8H',
                    'Content-Type': 'application/json'
                },
            }).then((res) => {
                console.log(res.data, 'hello id data')
                API.graphql({
                    query: createAmenity,
                    variables: {
                        input: {
                            id: res?.data?.item?.item_id,
                            societyId: society.id,
                            name: form.facility_type,
                            slots: slots,
                            free: form.allow_to_pay ? false : true,
                            cost: form.allow_to_pay ? form.paid : 0,
                            active: true,
                        }
                    }
                }).then((res) => {
                    console.log(res.data, 'hello data of graphql')
                    setTimeout(() => {
                        toast.success('Facility created successfully')
                        showSpinner(false)
                        navigate('/facility')
                    }, 3000)
                })
            })
    }

    const handleEndTimeChange = (time) => {
        setSelectedEndTime(time);
        let timeDiff = Math.abs(selectedStartTime - time);
        let hoursDiff = parseInt(timeDiff / (1000 * 60 * 60));
        if (hoursDiff > 0) {
            if (hoursDiff >= 3) {
                setDuration([{
                    value: '1 hour',
                    label: '1 hour'
                },
                {
                    value: '2 hours',
                    label: '2 hours'
                },
                {
                    value: '3 hours',
                    label: '3 hours'
                },
                ])
            }
            if (hoursDiff >= 2 && hoursDiff <= 3) {
                setDuration([{
                    value: '1 hour',
                    label: '1 hour'
                },
                {
                    value: '2 hours',
                    label: '2 hours'
                },
                ])
            }
            if (hoursDiff >= 1 && hoursDiff < 2) {
                setDuration([{
                    value: '1 hour',
                    label: '1 hour'
                }])
            }
        }
    };

    const handleStartTimeChange = (time) => {
        setSelectedStartTime(time)
    }

    return (
        <section>
            <div className='container-fluid'>
                <div className='row justify-content-center'>
                    <div className='col-12 col-lg-10 col-xl-8'>
                        <div className='header mt-md-5'>
                            <div className='header-body'>
                                <div className='row align-items-center'>
                                    <div className='col'>
                                        <h6 className='header-pretitle'>
                                            {society.name}
                                        </h6>
                                        <h1 className='header-title'>
                                            Create Facility Type
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Formik initialValues={formData} validationSchema={formSchema} onSubmit={handleCreatefacility} validateOnChange={false} validateOnBlur={false} enableReinitialize>
                            {({ handleChange, handleSubmit, values, errors, touched }) => {
                                return (
                                    <form className='mb-4' onSubmit={handleSubmit}>
                                        <FormControl type='text' title='Facility Name' name='facility_type' placeholder='Facility Name' value={values.facility_type} error={errors.facility_type} onChange={handleChange} required={true} />
                                        <div >
                                            <label className='form-label mb-0 p-0 d-flex justify-content-between'>
                                                <div className={!values.allow_to_pay ? 'text-muted' : ''}>
                                                    Paid<span className='text-danger'> *</span>
                                                </div>
                                                <div>
                                                    <FormControl
                                                        type="checkbox"
                                                        name="allow_to_pay"
                                                        value={values.allow_to_pay}
                                                        onChange={handleChange}
                                                        className='mb-1 p-0'
                                                        form={true}
                                                    />
                                                </div>
                                            </label>
                                            <FormControl type='money' name='paid' placeholder={values.allow_to_pay ? 'Enter Price' : 'Free'} value={values.allow_to_pay ? values.paid : null} error={errors.paid} onChange={handleChange} required={true} disabled={!values.allow_to_pay} />
                                        </div>

                                        <h3>Availability</h3>

                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                <div className="form-group d-flex flex-column">
                                                    <label>
                                                        From <span className="text-danger">*</span>
                                                    </label>
                                                    <ReactDatePicker
                                                        selected={selectedStartTime}
                                                        onChange={handleStartTimeChange}
                                                        name='start_time'
                                                        value={new Date(values.start_time)}
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={15}
                                                        timeCaption="Time"
                                                        dateFormat="h:mm aa"
                                                        placeholderText="Select Time"
                                                        className='form-control'
                                                    />
                                                    {!selectedStartTime && touched.start_time && <div className='text-danger mt-2 ms-1 h5'>Required.</div>}
                                                </div>
                                            </div>
                                            <div className='col-lg-6'>
                                                <div className="form-group d-flex flex-column">
                                                    <label className={!selectedStartTime && 'text-muted'}>
                                                        To <span className="text-danger">*</span>
                                                    </label>
                                                    <ReactDatePicker
                                                        disabled={!selectedStartTime}
                                                        selected={selectedEndTime}
                                                        onChange={handleEndTimeChange}
                                                        minTime={new Date().setHours(selectedStartTime?.getHours(), selectedStartTime?.getMinutes() + 30)} // Set the minimum time to 00:00
                                                        maxTime={new Date().setHours(23, 45)}
                                                        value={new Date(values.end_time)}
                                                        name='end_time'
                                                        showTimeSelect
                                                        showTimeSelectOnly
                                                        timeIntervals={15}
                                                        timeCaption="Time"
                                                        dateFormat="h:mm aa"
                                                        placeholderText="Select Time"
                                                        className='form-control'
                                                    />
                                                    {!selectedEndTime && touched.end_time && <div className='text-danger mt-2 ms-1 h5'>Required.</div>}
                                                </div>
                                            </div>
                                            {
                                                selectedEndTime && selectedStartTime &&
                                                <FormControl type="select" title="Duration" name='duration' options={duration} value={values.duration} error={errors.duration} onChange={handleChange} required={true} />
                                            }
                                        </div>

                                        <div className='form-group'>
                                            <label className='form-label mb-0'>Number of books per slot <span className='text-danger'>*</span></label>
                                            <NumberFormat
                                                className='form-control'
                                                name='books_per_slot'
                                                value={values.books_per_slot}
                                                onChange={handleChange}
                                                placeholder='Enter No of books per slot'
                                                maxLength={3}
                                            />
                                            {errors.books_per_slot && touched.books_per_slot && <div className='text-danger mt-2 ms-1 h5'>{errors.books_per_slot}</div>}
                                        </div>


                                        <Spinner show={spinner}>
                                            <button className='btn w-100 btn-primary' type='submit'>Create Facility Type</button>
                                        </Spinner>
                                        <div className='btn w-100 btn-link text-muted mt-2' onClick={() => handleCnacleCreate()} type='button'>
                                            Cancel
                                        </div>
                                    </form>
                                )
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CreateFacilityType
