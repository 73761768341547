import { API } from 'aws-amplify'
import { Formik } from 'formik'
import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as Yup from 'yup'
import FormControl from '../../../components/formControl'
import Spinner from '../../../components/spinner'
import { customSearchBuildings } from '../../../custumQueries/custumQueries'
import { createUnit } from '../../../graphql/mutations'

const CreateUnit = () => {
    const navigate = useNavigate();
    const unitType = require('../../../data/unitType.json')
    const society = useSelector((store) => store.user_society)

    const [buildings, setBuildings] = useState([])

    const [spinner, showSpinner] = useState(false)

    const formData = {
        building: '',
        unit_name: '',
        description: '',
        size: '',
        unit_type: ''
    }

    const formSchema = Yup.object().shape({
        unit_name: Yup.string().required('Required.'),
        description: Yup.string().required('Required.'),
        size: Yup.number().required('Required.'),
        unit_type: Yup.string().required('Required.'),
        building: Yup.object().required('Required.')
    })

    const handleListBuildings = async () => {
        try {
            await API.graphql({
                query: customSearchBuildings,
                variables: {
                    filter: { societyId: { eq: society.id } }
                }
            }).then((res) => {
                for (let buildingItem of res?.data?.searchBuildings?.items) {
                    buildingItem.label = buildingItem.name
                    buildingItem.value = buildingItem.id
                }
                setBuildings(res?.data?.searchBuildings?.items)
            })
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        handleListBuildings()
        // eslint-disable-next-line
    }, [society])

    const handleCreateUnit = async (form) => {
        showSpinner(true)
        try {
            await API.graphql({
                query: createUnit,
                variables: {
                    input: {
                        buildingId: form.building.id,
                        societyId: society.id,
                        name: form.unit_name,
                        size: form.size,
                        description: form.description,
                        type: form.unit_type
                    }
                }
            })
            setTimeout(() => {
                navigate('/units')
                toast.success('Unit created successfully')
            }, 3000)
        } catch (err) {
            showSpinner(false)
            console.log(err)
        }
    }

    const handleCnacleCreate = () => {
        navigate(-1)
    }

    return (
        <section>
            <div className='container-fluid'>
                <div className='row justify-content-center'>
                    <div className='col-12 col-lg-10 col-xl-8'>
                        <div className='header mt-md-5'>
                            <div className='header-body'>
                                <div className='row align-items-center'>
                                    <div className='col'>
                                        <h6 className='header-pretitle'>
                                            {society.name}
                                        </h6>
                                        <h1 className='header-title'>
                                            Create Unit
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Formik initialValues={formData} validationSchema={formSchema} onSubmit={handleCreateUnit} validateOnChange={false} validateOnBlur={false}>
                            {({ handleChange, handleSubmit, values, errors, touched }) => {
                                const listUnitType = values.unit_type ? unitType.filter((x) => x.value === values.unit_type)[0] : null;
                                return (
                                    <form className='mb-4' onSubmit={handleSubmit}>

                                        <FormControl type='select' name='building' title='Select building' options={buildings} required={true}
                                            value={values.building} error={errors.building} onChange={handleChange} />

                                        <FormControl type='text' title='Name' name='unit_name' placeholder={'Unit Name'} value={values.unit_name} error={errors.unit_name} onChange={handleChange} required={true} />

                                        <FormControl type='text' title='Description' name='description' placeholder={'Description'} value={values.description} error={errors.description} onChange={handleChange} required={true} />

                                        <div className='form-group'>
                                            <label className='form-label mb-0'>Size<span className='text-danger'> *</span></label>
                                            <NumberFormat
                                                className='form-control'
                                                placeholder='Eg: 10'
                                                name='size'
                                                value={values.size}
                                                onChange={handleChange}
                                                maxLength={5}
                                            />
                                            {errors.size && touched.size && <div className='text-danger mt-2 ms-1 h5'>{errors.size}</div>}
                                        </div>

                                        <FormControl type='select1' title='Select type' name='unit_type' value={listUnitType} options={unitType} error={errors.unit_type} onChange={handleChange} required={true} />

                                        <Spinner show={spinner}>
                                            <button className='btn w-100 btn-primary' type='submit'>Create Unit</button>
                                        </Spinner>
                                        <div className='btn w-100 btn-link text-muted mt-2' onClick={() => handleCnacleCreate()} type='button'>
                                            Cancel
                                        </div>
                                    </form>
                                )
                            }}
                        </Formik>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CreateUnit
