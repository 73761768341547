import { API } from 'aws-amplify'
import { Formik } from 'formik'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import DatePicker from "react-datepicker";
import * as Yup from 'yup'
import FormControl from '../../../../components/formControl'
import Spinner from '../../../../components/spinner'
import { custumGetAllSocietyData } from '../../../../custumQueries/custumQueries'
import { useEffect } from 'react'
import moment from 'moment'
import { filterPassedTime } from '../../../../helpers/utils'
import axios from 'axios'
import NumberFormat from 'react-number-format'

const CreateRecurrringInvoice = () => {
    const recurring = require('../../../../data/recurringInvoice.json')
    const zohoId = useSelector(store => store.zohoId)
    const society = useSelector((store) => store.user_society)
    const navigate = useNavigate();

    const [invoices, setInvoices] = useState()
    const [formSpinner, showFormSpinner] = useState(false)
    const [spinner, showSpinner] = useState(false)
    const [units, setUnits] = useState()

    const formData = {
        unit: '',
        invoice: '',
        recurring_type: '',
        date: '',
        end_date: '',
        amount: '',
        repeat_every: '',
        recurrence_name: ''
    }

    const handelGetInvoiceItem = () => {
        showFormSpinner(true)
        axios.get(`https://m4ppic541a.execute-api.ap-south-1.amazonaws.com/dev/items?organization_id=${zohoId}`, {
            headers: {
                "x-api-key": "bxDJCXmPRk92r7bIdc3Kh8PwAZeCMeFb4Yv0BU8H"
            }
        }).then(
            (res) => {
                for (let item of res.data.items) {
                    item.label = item.name
                    item.value = item.item_id
                }
                setInvoices(res.data.items)
                showFormSpinner(false)
            })
    }

    const handleFormDataOptions = async () => {
        try {
            await API.graphql({
                query: custumGetAllSocietyData,
                variables: {
                    id: society.id
                }
            }).then((res) => {
                for (let item of res?.data?.getSociety?.units?.items) {
                    item.label = item.name
                    item.value = item.id
                }
                setUnits(res?.data?.getSociety?.units?.items)
            })
        } catch (err) {
            console.log(err)
        }
    }

    const formSchema = Yup.object().shape({
        recurrence_name: Yup.string().required('Required.'),
        unit: Yup.object().required('Required.'),
        date: Yup.string().required('Required.'),
        end_date: Yup.string().when('expaier_validate', (expaier_validate) => {
            if (!expaier_validate) {
                return Yup.string().required('Required.')
            }
        }),
        amount: Yup.number().required('Required.'),
        recurring_type: Yup.object().required('Required.'),
        invoice: Yup.object().required('Required'),
        repeat_every: Yup.number().required('Required')
    })


    const handleCnacleCreate = () => {
        navigate('/accounting/recurring-invoice')
    }


    const handleCreateRecurringInvoice = async (form) => {
        showSpinner(true)
        let address = JSON.parse(society.address)
        let data =
        {
            "recurrence_name": form.recurrence_name,
            "recurrence_frequency": form.recurring_type.value,
            "repeat_every": form.repeat_every,
            "customer_name": form.unit.name,
            "customer_id": form.unit.zohoId,
            "currency_id": "3769395000000000099",
            "currency_code": "INR",
            // eslint-disable-next-line
            "start_date": new Date(form.date).toLocaleDateString('en-CA').split('/').reverse().join('-'),
            "end_date": form?.end_date ? new Date(form.end_date).toLocaleDateString('en-CA').split('/').reverse().join('-') : '',
            "line_items": [
                {
                    "item_id": form.invoice.item_id,
                    "name": form.invoice.item_name,
                    "item_name": form.invoice.item_name,
                    "status": "active",
                    "rate": form.amount,
                }
            ],
            "billing_address": {
                "address": address.city ? address.city.trim() : '',
                "city": address.city,
                "state": address.state,
                "zip": address.zip,
                "country": "INDIA",
            }
        }
        axios.post(`https://m4ppic541a.execute-api.ap-south-1.amazonaws.com/dev/recurringinvoices?organization_id=${zohoId}`, data, {
            headers: {
                'x-api-key': 'bxDJCXmPRk92r7bIdc3Kh8PwAZeCMeFb4Yv0BU8H',
                'Content-Type': 'application/json'
            }
        }).then((res) => {
            showSpinner(false)
            navigate('/accounting/recurring-invoice')
            toast.success('Created successfully')
        }).catch((err) => {
            if (err.message === 'Request failed with status code 400') {
                toast.error('Invoic Name already existed try with different name')
                showSpinner(false)
            }
        })
    }

    useEffect(() => {
        handelGetInvoiceItem()
        handleFormDataOptions()
        // eslint-disable-next-line
    }, [])

    return (
        <section>
            <div className='container-fluid'>
                <div className='row justify-content-center'>
                    <div className='col-12 col-lg-10 col-xl-8'>
                        <div className='header mt-md-5'>
                            <div className='header-body'>
                                <div className='row align-items-center'>
                                    <div className='col'>
                                        <h6 className='header-pretitle'>
                                            {society.name}
                                        </h6>
                                        <h1 className='header-title'>
                                            Create Recurring Invoice
                                        </h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            zohoId ?
                                <Formik initialValues={formData} validationSchema={formSchema} onSubmit={handleCreateRecurringInvoice} validateOnChange={false} validateOnBlur={false}>
                                    {({ handleChange, handleSubmit, values, errors, setFieldValue, touched }) => {
                                        return (
                                            <Spinner show={formSpinner}>
                                                <form className='mb-4' onSubmit={handleSubmit}>
                                                    <FormControl type='text' name='recurrence_name' title='Invoice Name' required value={values?.recurrence_name} error={errors?.recurrence_name} onChange={handleChange} />

                                                    <FormControl type='select' name='unit' title='Select Unit' options={units} required={true}
                                                        value={values.unit} error={errors.unit} onChange={handleChange} />

                                                    <FormControl type='select' name='invoice' title='Select Invoice' options={invoices} required={true}
                                                        value={values.invoice} error={errors.invoice} onChange={(e) => { handleChange(e); setFieldValue('amount', e.target.value.rate ? e.target.value.rate : 0) }} />

                                                    <FormControl type='select' name='recurring_type' title='Select Frequency' options={recurring} required={true}
                                                        value={values.recurring_type} error={errors.recurring_type} onChange={handleChange} />

                                                    <div className="form-group d-flex flex-column">
                                                        <label>
                                                            Starts on <span className="text-danger">*</span>
                                                        </label>
                                                        <DatePicker
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            autoComplete="off"
                                                            name="date"
                                                            className="form-control"
                                                            filterTime={filterPassedTime}
                                                            minDate={moment().toDate()}
                                                            dateFormat="MMM d, yyyy"
                                                            selected={
                                                                values.date ? moment(values.date).toDate() : ""
                                                            }
                                                            onChange={(date) => {
                                                                setFieldValue("date", date);
                                                            }}
                                                        />
                                                        {errors.date && touched.date && (
                                                            <div className="text-danger mt-2 ms-1 h5">
                                                                {errors.date}
                                                            </div>
                                                        )}
                                                    </div>

                                                    <FormControl
                                                        type="checkbox"
                                                        className='m-0 p-0'
                                                        name="expaier_validate"
                                                        label="Never Expires"
                                                        value={values.expaier_validate}
                                                        onChange={handleChange}
                                                    />

                                                    <div className="form-group d-flex flex-column">
                                                        <label className={(values?.expaier_validate || !values?.date) ? 'text-muted' : ''}>
                                                            Ends On <span className="text-danger">*</span>
                                                        </label>
                                                        <DatePicker
                                                            disabled={!values.date || values?.expaier_validate}
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            autoComplete="off"
                                                            name="end_date"
                                                            className={`form-control ${(values?.expaier_validate || !values?.date) && 'bg-light'}`}
                                                            filterTime={filterPassedTime}
                                                            minDate={new Date(values.date)}
                                                            dateFormat="MMM d, yyyy"
                                                            selected={
                                                                values.end_date ? moment(values.end_date).toDate() : ""
                                                            }
                                                            onChange={(date) => {
                                                                setFieldValue("end_date", date);
                                                            }}
                                                        />
                                                        {errors.end_date && touched.end_date && (
                                                            <div className="text-danger mt-2 ms-1 h5">
                                                                {errors.end_date}
                                                            </div>
                                                        )}
                                                    </div>

                                                    {
                                                        values.recurring_type &&
                                                        <div className='form-group'>
                                                            <label className='form-label mb-0'>Repeat Every <span className='text-danger'>*</span></label>
                                                            <NumberFormat
                                                                className='form-control'
                                                                name='repeat_every'
                                                                value={values.repeat_every}
                                                                onChange={handleChange}
                                                                placeholder={`Repeat for ever 'n' ${values.recurring_type.value}`}
                                                                maxLength={2}
                                                            />
                                                            {errors?.repeat_every && touched.repeat_every && <div className='text-danger mt-2 ms-1 h5'>{errors?.repeat_every}</div>}
                                                        </div>
                                                    }

                                                    {
                                                        values.invoice &&
                                                        <div className='form-group'>
                                                            <label className='form-label mb-0'>Amount<span className='text-danger'> *</span></label>
                                                            <input className='form-control bg-light' type='money' name='amount' placeholder='Amount' value={`Rs.${values.amount}`} disabled />
                                                        </div>
                                                    }

                                                    <Spinner show={spinner}>
                                                        <button className='btn w-100 btn-primary' type='submit'>Create Recurring Invoice</button>
                                                    </Spinner>
                                                    <div className='btn w-100 btn-link text-muted mt-2' onClick={() => handleCnacleCreate()} type='button'>
                                                        Cancel
                                                    </div>
                                                </form>
                                            </Spinner>
                                        )
                                    }}
                                </Formik> :
                                <>
                                    <h4 className='m-0 p-2 text-center bg-warning'>
                                        <i className="fa fa-warning me-2" />
                                        Society is not mapped to the zoho account please contact the admins to map the society
                                    </h4>
                                    <div className='btn w-100 btn-link text-muted mt-2' onClick={() => handleCnacleCreate()} type='button'>
                                        Cancel
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default CreateRecurrringInvoice
