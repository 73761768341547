import axios from 'axios'
import { Formik } from 'formik'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import DatePicker from "react-datepicker"
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import swal from 'sweetalert'
import Spinner from '../../../../components/spinner'
import { filterPassedTime } from '../../../../helpers/utils'
import Header from '../../../../layouts/header'

function Invoice() {
    const navigate = useNavigate()
    const zohoId = useSelector(store => store.zohoId)

    const [modalSpinner, showModalSpinner] = useState(false)
    const [modal, showModal] = useState(false)
    const [invoice, setInvoice] = useState()
    const [spinner, showSpinner] = useState(false)
    const [formData, setFormData] = useState({
        unit: '',
        date: '',
        due_date: '',
        amount: '',
        due_amount: '',
    })

    const handelGetInvoice = () => {
        if (zohoId) {
            showSpinner(true)
            axios.get(`https://m4ppic541a.execute-api.ap-south-1.amazonaws.com/dev/invoices?organization_id=${zohoId}`, {
                headers: {
                    'x-api-key': 'bxDJCXmPRk92r7bIdc3Kh8PwAZeCMeFb4Yv0BU8H'
                }
            }).then((res) => {
                setInvoice(res.data.invoices)
                showSpinner(false)
            })
        }
    }

    useEffect(() => {
        handelGetInvoice()
        // eslint-disable-next-line
    }, [zohoId])

    const handleAddInvoices = () => {
        navigate('/accounting/create/invoice')
    }

    const handleEdit = (item, status) => {
        if (status) {
            showModal(true)
            showModalSpinner(true)
            axios.get(`https://m4ppic541a.execute-api.ap-south-1.amazonaws.com/dev/invoices/${item.invoice_id}?organization_id=${zohoId}`, {
                headers: {
                    'x-api-key': 'bxDJCXmPRk92r7bIdc3Kh8PwAZeCMeFb4Yv0BU8H'
                }
            }).then((res) => {
                setFormData({
                    id: item.invoice_id,
                    unit: item?.customer_name,
                    date: new Date(item?.date).toLocaleDateString('en-CA').split('/').reverse().join('-'),
                    due_date: new Date(item?.due_date).toLocaleDateString('en-CA').split('/').reverse().join('-'),
                    amount: item.total,
                    due_amount: item.balance,
                    item_id: res.data.invoice.line_items[0].item_id,
                    item_name: res.data.invoice.line_items[0].name,
                    rate: res.data.invoice.rate,
                })
                showModalSpinner(false)
            })
        }
        else {
            toast.error('Sent or paid invoices can not be editable')
        }
    }

    const handelUpdateInvoice = (form) => {
        showSpinner(true)
        try {
            let data =
            {
                "date": new Date(form.date).toISOString().split('T')[0],
                "due_date": new Date(form.due_date).toISOString().split('T')[0],
                "line_items": [
                    {
                        "item_id": formData.item_id,
                        "rate": form.amount,
                    }
                ],
            }
            axios.put(`https://m4ppic541a.execute-api.ap-south-1.amazonaws.com/dev/invoices/${formData.id}?organization_id=${zohoId}`, data, {
                headers: {
                    'x-api-key': 'bxDJCXmPRk92r7bIdc3Kh8PwAZeCMeFb4Yv0BU8H',
                    'Content-Type': 'application/json'
                }
            }).then((res) => {
                setInvoice((prevState) => {
                    const index = prevState.findIndex((invoice) => invoice.invoice_id === formData.id);
                    prevState[index] = res.data.invoice;
                    return [...prevState];
                })
                toast.success('Updated successfully')
                showModal(false)
                showSpinner(false)
            })
        }
        catch (err) {
            console.log(err)
        }
    }

    const handelDeleteInvoice = (invoices, index) => {
        swal({
            title: `Delete`,
            text: `Are you sure you want to delete Invoice?`,
            buttons: ['Cancel', 'Delete'],
            icon: 'warning',
            dangerMode: true,
        }).then((status) => {
            showSpinner(true)
            if (status) {
                showSpinner(true)
                axios.delete(`https://m4ppic541a.execute-api.ap-south-1.amazonaws.com/dev/invoices/${invoices.invoice_id}?organization_id=${zohoId}`, {
                    headers: {
                        'x-api-key': 'bxDJCXmPRk92r7bIdc3Kh8PwAZeCMeFb4Yv0BU8H',
                        'Content-Type': 'application/json'
                    }
                }).then(() => {
                    const temp = [...invoice]
                    temp.splice(index, 1)
                    setInvoice(temp)
                    toast.success('Invoice deleted successfully')
                    showSpinner(false)
                }).catch((err) => toast.error(err.message))
            }
            showSpinner(false)
        }
        )
    }

    return (
        <section>
            <Header title={'Invoice'} onclick={handleAddInvoices} buttonTitle='Create Invoices' />
            <div className='tab-content pb-3 d-none d-xl-block'>
                <div className='tab-pane fade active show' id='companiesListPane' role='tabpanel' aria-labelledby='companiesListTab'>
                    <div className='card' id='companiesList'>
                        <div className='table-responsive' key={invoice?.length}>
                            <Spinner show={spinner}>
                                <table className='table table-hover table-nowrap card-table'>
                                    <thead>
                                        <tr>
                                            <th className='ms-5'>Unit Name</th>
                                            <th className='text-center'>Invoice date</th>
                                            <th className='text-center'>Due date</th>
                                            <th className='text-center'>Invoice Number</th>
                                            <th className='text-center'>Total Amount</th>
                                            <th className='text-center'>Due amount</th>
                                            <th className='text-center'>Status</th>
                                            <th className='text-center'>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody className='list fs-base cursor-pointer'>
                                        {zohoId ? invoice?.map((item, index) => (
                                            <tr key={index}>
                                                <td onClick={() => navigate(`/accounting/${item?.invoice_id}/invoice`)} className='ms-5 p-0'>{item?.customer_name}</td>
                                                <td onClick={() => navigate(`/accounting/${item?.invoice_id}/invoice`)} className='text-center'>{item?.date}</td>
                                                <td onClick={() => navigate(`/accounting/${item?.invoice_id}/invoice`)} className='text-center'>
                                                    {!item?.due_days ? item?.due_date :
                                                        item?.due_days.includes('Due in') ? <span className="text-yellow"><i className="fa fa-warning"></i> {item.due_days}</span> : item?.due_days.includes('Overdue') ? <span className="text-danger">{item?.due_days} </span> : <span className='text-yellow'><i className="fa fa-warning" /> {item?.due_days}</span>}
                                                </td>
                                                <td onClick={() => navigate(`/accounting/${item?.invoice_id}/invoice`)} className='text-center'>{item?.invoice_number}</td>
                                                <td onClick={() => navigate(`/accounting/${item?.invoice_id}/invoice`)} className='text-center'>{item?.currency_symbol + item?.total}</td>
                                                <td onClick={() => navigate(`/accounting/${item?.invoice_id}/invoice`)} className='text-center'>{item?.currency_symbol + item?.balance}</td>
                                                <td className='text-center'>
                                                    {
                                                        item?.status === 'paid' ?
                                                            <span className="badge bg-success-soft">Paid</span>
                                                            :
                                                            item?.status === 'overdue' ?
                                                                <span className="badge bg-danger-soft">Overdue</span>
                                                                :
                                                                item?.status === 'sent' ?
                                                                    <span className="badge bg-warning-soft">Sent mail</span>
                                                                    :
                                                                    item?.status === 'draft' &&
                                                                    <span className="badge bg-light-soft text-black">Draft</span>
                                                    }
                                                </td>
                                                <td className='text-center'>
                                                    <div className='dropdown'>
                                                        <Link className='dropdown-ellipses dropdown-toggle' role='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='true'>
                                                            <i className='fe fe-more-vertical'></i>
                                                        </Link>
                                                        <div className='dropdown-menu' aria-labelledby='dropdownMenuLink'>
                                                            <div className='dropdown-item cursor-pointer' onClick={() => { handleEdit(item, item?.status === 'draft' ? true : false) }}>
                                                                Edit
                                                            </div>
                                                            <div className='dropdown-item cursor-pointer' onClick={() => { handelDeleteInvoice(item, index) }}>
                                                                Delete
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )) :
                                            <tr className='text-center py-3'>
                                                <td colSpan={'7'}>
                                                    <h3 className='m-0 text-yellow'>
                                                        <i className="fa fa-warning me-2" />
                                                        Society is not mapped to the zoho account please contact the admins to map the society
                                                    </h3>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </Spinner>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container d-lg-block d-xl-none p-0">
                <Spinner show={spinner}>
                    {
                        zohoId ? invoice?.map((item, index) => (
                            <div key={index}>
                                <div>
                                    <div className='d-flex justify-content-between bg-white rounded' onClick={() => navigate(`/accounting/${item?.invoice_id}/invoice`)}>
                                        <div className="text-start d-flex align-items-center">
                                            <div className='' onClick={(e) => { e.stopPropagation() }}>
                                                <i className="px-3 fa fa-trash-o text-danger" onClick={() => { handelDeleteInvoice(item, index) }} /><br /><br />
                                                <i className=' px-3 fe fe-edit text-primary' onClick={() => { handleEdit(item, item?.status === 'draft' ? true : false) }} />
                                            </div>
                                            <div>
                                                <strong>{item?.customer_name}</strong>
                                                <div>{item?.invoice_number}</div>
                                                <div className='text-muted'>{item?.date}</div>
                                            </div>
                                        </div>
                                        <div className="pe-3 text-end">
                                            <div>₹ {item?.total}</div>
                                            <div style={{ 'font-size': '13px' }}>
                                                {
                                                    item?.status === 'draft' ? <span className='text-muted'>DRAFT</span> : item?.status !== 'paid' && item?.status !== 'draft' ? <span className='text-primary'>{(item?.status !== 'draft') && <>{item?.due_days ? <>{item?.due_days}</> : <>{item?.due_date}</>}</>}  </span> : item?.status === 'paid' && <span className='text-success'>PAID</span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr className='p-1 m-1' />
                            </div>
                        ))
                            :
                            <h3 className='m-0 text-yellow'>
                                <i className="fa fa-warning me-2" />
                                Society is not mapped to the zoho account please contact the admins to map the society
                            </h3>
                    }
                </Spinner>
            </div>
            <Modal show={modal} onHide={() => showModal(false)}>
                <Modal.Body>
                    <h3>Edit Invoice</h3>
                    <hr />
                    <Spinner show={modalSpinner}>
                        <Formik initialValues={formData} onSubmit={handelUpdateInvoice} validateOnChange={false} validateOnBlur={false} enableReinitialize>
                            {({ handleChange, handleSubmit, values, errors, touched, setFieldValue }) => {
                                return (
                                    <form className='mb-4' onSubmit={handleSubmit}>

                                        <div className='form-group'>
                                            <label className='form-label mb-0'>Unit Name<span className='text-danger'>*</span></label>
                                            <input className='form-control bg-light' type='text' name='unit' placeholder='e.g. Investor' value={values.unit} onChange={handleChange} disabled />
                                        </div>

                                        <div className="form-group d-flex flex-column">
                                            <label>
                                                Invoice Date <span className="text-danger">*</span>
                                            </label>
                                            <DatePicker
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                autoComplete="off"
                                                name="date"
                                                className="form-control"
                                                filterTime={filterPassedTime}
                                                minDate={moment().toDate()}
                                                dateFormat="MMM d, yyyy"
                                                selected={
                                                    values.date ? moment(values.date).toDate() : ""
                                                }
                                                onChange={(date) => {
                                                    setFieldValue("date", date);
                                                }}
                                            />
                                            {errors.date && touched.date && (
                                                <div className="text-danger mt-2 ms-1 h5">
                                                    {errors.date}
                                                </div>
                                            )}
                                        </div>
                                        <div className="form-group d-flex flex-column">
                                            <label className={!values.date && "disable"}>
                                                Due Date <span className="text-danger">*</span>
                                            </label>
                                            <DatePicker
                                                disabled={!values.date}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                autoComplete="off"
                                                name="due_date"
                                                className="form-control"
                                                filterTime={filterPassedTime}
                                                minDate={new Date(values.date)}
                                                dateFormat="MMM d, yyyy"
                                                selected={
                                                    values.due_date ? moment(values.due_date).toDate() : ""
                                                }
                                                onChange={(date) => {
                                                    setFieldValue("due_date", date);
                                                }}
                                            />
                                            {errors.due_date && touched.due_date && (
                                                <div className="text-danger mt-2 ms-1 h5">
                                                    {errors.due_date}
                                                </div>
                                            )}
                                        </div>

                                        <div className='form-group'>
                                            <label className='form-label mb-0'>Amount<span className='text-danger'> *</span></label>
                                            <input className='form-control bg-light' type='money' name='amount' placeholder='Amount' value={`Rs.${values.amount}`} disabled />
                                        </div>

                                        <div className='form-group'>
                                            <label className='form-label mb-0'>Due Amount<span className='text-danger'> *</span></label>
                                            <input className='form-control bg-light' type='money' name='due_amount' placeholder='Amount' value={`Rs.${values.due_amount}`} disabled />
                                        </div>

                                        <Spinner show={spinner}>
                                            <button className='btn btn-primary' type='submit'>Update</button>
                                            <button className='btn btn-outline-secondary ms-2' type='button' onClick={() => showModal(false)}>Cancel</button>
                                        </Spinner>
                                    </form>
                                )
                            }
                            }
                        </Formik>
                    </Spinner>
                </Modal.Body>
            </Modal>
        </section>
    )
}

export default Invoice
