import { API, Auth } from 'aws-amplify'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { searchSocieties } from '../graphql/queries'

function TopNav() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const listSocieties = useSelector((state) => state.society)


    const handleSelect = async (id) => {
        try {
            const society = await API.graphql({
                query: searchSocieties,
                variables: {
                    filter: { id: { eq: id } }
                }
            })
            dispatch({
                type: 'user_society', payload: society.data.searchSocieties.items[0]
            })
            navigate('/dashboard')
        } catch (err) {
            console.log(err)
        }
    }

    const handleSignOut = () => {
        Auth.signOut().then((res) => {
            localStorage.clear();
            navigate('/')
        })
    }

    return (
        <nav className='navbar navbar-expand-md navbar-light d-none d-md-flex' id='topbar'>
            <div className='container-fluid'>
                <form className='form-inline me-4 d-none d-md-flex'>
                </form>
                <div className='navbar-user'>
                    <div className='dropdown'>
                        <Link id='sidebarIcon' className='dropdown-toggle' role='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                            <div className='avatar avatar-sm avatar-onlines'>
                                <img src='/img/profile.png' className='avatar-img rounded-circle' alt='...' />
                            </div>
                        </Link>
                        <div className='dropdown-menu dropdown-menu-end' aria-labelledby='sidebarIcon'>
                            {listSocieties?.length !== 1 && listSocieties?.map((item, index) => (
                                <div key={index}>
                                    <div onClick={() => handleSelect(item.societyId)} className='dropdown-item m-0' style={{ 'cursor': 'pointer' }} key={index}>{item?.society?.name}</div>
                                </div>
                            ))}
                            {listSocieties?.length !== 1 &&
                                <hr className='dropdown-divider' />
                            }
                            <Link className='dropdown-item' to='/profile'>Profile</Link>
                            <Link className='dropdown-item' onClick={() => handleSignOut()}>Logout</Link>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default TopNav
