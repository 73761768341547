import { Route, Routes } from 'react-router-dom';
import Login from './pages/auth/login';
import Buildings from './pages/society/buildings/buildings';
import CreateBuilding from './pages/society/buildings/createBuilding';
import AdminLayout from './layouts/adminLayout';

import { Amplify } from 'aws-amplify';
import { ToastContainer } from 'react-toastify';
import awsconfig from './aws-exports';
import CreateResident from './pages/society/handleResidents/residents/createResident';
import Residents from './pages/society/handleResidents/residents/residents'
import CreateUnit from './pages/society/unit/createUnit';
import Unit from './pages/society/unit/units';
import Visitor from './pages/society/visitor/visitor'
import Dashboard from './pages/society/dashboard/dashboard';
import ResidentLayoutByUnit from './pages/society/handleResidents/residentsByUnit/residentAdminLayoutByUnit';
import Invoice from './pages/society/accounting/invoice/invoice';
import CreateInvoice from './pages/society/accounting/invoice/createInvoice';
import QrCode from './pages/society/qrCode/qrCode';
import CreateComplaint from './pages/society/complaints/createComplaintType';
import ComplaintAdminLayout from './pages/society/complaints/complaintAdminLayout';
import FacilityAdminLayout from './pages/society/facilityManagement/facilityAdminLayout';
import CreateFacilityType from './pages/society/facilityManagement/createFacilityType';
import AddResident from './pages/society/handleResidents/residents/addResident';
import Profile from './pages/profile/profile';
import SocietyStaff from './pages/society/societyStaff/societyStaff';
import CreateSocietyStaff from './pages/society/societyStaff/createSocietyStaff';
import AddSocietyUser from './pages/society/societyStaff/addSocietyUser';
import RecurringInvoice from './pages/society/accounting/recurringInvoices/recurringInvoice';
import CreateRecurringInvoice from './pages/society/accounting/recurringInvoices/createRecurringInvoice';
import ProfitLoss from './pages/society/reports/profitLoss';
import BalanceSheet from './pages/society/reports/balanceSheet';
import CashFlowStatements from './pages/society/reports/cashFlowStatements';
import InvoiceDetail from './pages/society/accounting/invoice/invoiceDetail';
import RecurringInvoiceDetailsPage from './pages/society/accounting/recurringInvoices/recurringInvoiceDetailsPage';
Amplify.configure(awsconfig);

function App() {
  return (
    <>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route element={<AdminLayout />} >
          <Route path='/dashboard' element={<Dashboard />} />

          <Route path='/building' element={<Buildings />} />
          <Route path='/building/create' element={<CreateBuilding />} />

          <Route path='/units' element={<Unit />} />
          <Route path='/unit/create' element={<CreateUnit />} />
          <Route path='/unit/:id/resident' element={<ResidentLayoutByUnit />} />

          <Route path='/resident' element={<Residents />} />
          <Route path='/resident/create' element={<CreateResident />} />
          <Route path='/resident/add' element={<AddResident />} />

          <Route path='/visitor' element={<Visitor />} />

          <Route path='/create/complaint-type' element={<CreateComplaint />} />
          <Route path='/complaint' element={<ComplaintAdminLayout />} />

          <Route path='/facility' element={<FacilityAdminLayout />} />
          <Route path='/create/facility_type' element={<CreateFacilityType />} />

          <Route path='/society-staff' element={<SocietyStaff />} />
          <Route path='/create/society-staff' element={<CreateSocietyStaff />} />
          <Route path='/add/society-staff' element={<AddSocietyUser />} />

          <Route path='/accounting/invoice' element={<Invoice />} />
          <Route path='/accounting/create/invoice' element={<CreateInvoice />} />
          <Route path='/accounting/:id/invoice' element={<InvoiceDetail />} />

          <Route path='/accounting/recurring-invoice' element={<RecurringInvoice />} />
          <Route path='/accounting/create/recurring-invoice' element={<CreateRecurringInvoice />} />
          <Route path='/accounting/:id/recurring-invoice' element={<RecurringInvoiceDetailsPage />} />

          <Route path='/reports/profitandloss' element={<ProfitLoss />} />
          <Route path='/reports/balance-sheet' element={<BalanceSheet />} />
          <Route path='/reports/cashflow' element={<CashFlowStatements />} />

          <Route path='/profile' element={<Profile />} />
        </Route>
        <Route path='/visitor/qr-code/:id' element={<QrCode />} />
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
