import { API } from 'aws-amplify'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Spinner from '../../../components/spinner'
import { searchBuildings, searchResidents, searchUnits } from '../../../graphql/queries'

function Dashboard() {
    const society = useSelector((state) => state.user_society)

    const [buildingTotal, setBuildingTotal] = useState()
    const [unitTotal, setUnitTotal] = useState()
    const [residentsTotal, setResidentsTotal] = useState()
    const [spinner, showSpinner] = useState(false)

    useEffect(() => {
        handelBuildingCount()
        // eslint-disable-next-line
    }, [society])

    const handelBuildingCount = () => {
        showSpinner(true)
        API.graphql({
            query: searchBuildings,
            variables: {
                filter: { societyId: { eq: society?.id } }
            }
        }).then((res) => {
            setBuildingTotal(res?.data?.searchBuildings?.total)
        })
        API.graphql({
            query: searchUnits,
            variables: {
                filter: { societyId: { eq: society?.id } }
            }
        }).then((res) => {
            setUnitTotal(res?.data?.searchUnits?.total)
        })
        API.graphql({
            query: searchResidents,
            variables: {
                filter: { societyId: { eq: society?.id } }
            }
        }).then((res) => {
            setResidentsTotal(res.data.searchResidents.total)
            showSpinner(false)
        })
    }

    return (
        <Spinner show={spinner}>
            <div className="container-fluid mt-4">
                <div className="row">
                    <div className="col-12 col-lg-6 col-xl">
                        <div className="card">
                            <div className="card-body">
                                <div className="row align-items-center gx-0">
                                    <div className="col">
                                        <h6 className="text-uppercase text-muted mb-2">
                                            Total Buildings
                                        </h6>
                                        <span className="h2 mb-0">
                                            {buildingTotal}
                                        </span>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fa fa-building fs-1 mt-3"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 col-xl">
                        <div className="card">
                            <div className="card-body">
                                <div className="row align-items-center gx-0">
                                    <div className="col">
                                        <h6 className="text-uppercase text-muted mb-2">
                                            Total Units
                                        </h6>
                                        <span className="h2 mb-0">
                                            {unitTotal}
                                        </span>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fa fa-house fs-1 mt-3"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 col-xl">
                        <div className="card">
                            <div className="card-body">
                                <div className="row align-items-center gx-0">
                                    <div className="col">
                                        <h6 className="text-uppercase text-muted mb-2">
                                            Total Residents
                                        </h6>
                                        <span className="h2 mb-0">
                                            {residentsTotal}
                                        </span>
                                    </div>
                                    <div className="col-auto">
                                        <i className="fa fa-user fs-1 mt-3"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Spinner>
    )
}

export default Dashboard
