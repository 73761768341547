import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { useEffect } from 'react'
import { API, Auth } from 'aws-amplify'
import { searchSocieties } from '../graphql/queries'
const buildingList = require('../data/buildingSidebar.json')
const entriesList = require('../data/entries.json')
const reports = require('../data/reports.json')

const Sidebar = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const listSocieties = useSelector((state) => state.society)
    const getSocietyName = useSelector((store) => store.user_society)
    const [societyName, setSocietyName] = useState()

    const handleSelect = async (value) => {
        try {
            const society = await API.graphql({
                query: searchSocieties,
                variables: {
                    filter: { id: { eq: value.societyId } }
                }
            })
            dispatch({
                type: 'user_society', payload: society.data.searchSocieties.items[0]
            })
            navigate('/dashboard')
        } catch (err) {
            console.log(err)
        }
    }

    const handleSignOut = () => {
        Auth.signOut().then((res) => {
            localStorage.clear();
            navigate('/')
        })
    }

    const handelGetZohoId = () => {
        API.graphql({
            query: searchSocieties,
            variables: {
                filter: { id: { eq: getSocietyName.id } }
            }
        }).then((res) => {
            dispatch({ type: 'zohoId', payload: res.data.searchSocieties.items[0].zohoId })
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        setSocietyName(getSocietyName?.name)
        handelGetZohoId()
        // eslint-disable-next-line
    }, [getSocietyName])

    return (
        <nav className="navbar navbar-vertical fixed-start navbar-expand-md navbar-light" id="sidebar">
            <div className="container-fluid">
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#sidebarCollapse" aria-controls="sidebarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="navbar-brand text-center">
                    <img src='/img/logo.png' alt='logo' className="navbar-brand-img mx-auto" />
                </div>
                <div className='navbar-user d-md-none'>
                    <div className='dropdown'>
                        <Link id='sidebarIcon' className='dropdown-toggle' role='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
                            <div className='avatar avatar-sm avatar-onlines'>
                                <img src='/img/profile.png' className='avatar-img rounded-circle' alt='...' />
                            </div>
                        </Link>
                        <div className='dropdown-menu dropdown-menu-end' aria-labelledby='sidebarIcon'>
                            {listSocieties?.length !== 1 && listSocieties?.map((item, index) => (
                                <div key={index}>
                                    <div onClick={() => handleSelect(item)} className='dropdown-item m-0' style={{ 'cursor': 'pointer' }}>{item?.society?.name}</div>
                                </div>
                            ))}
                            {listSocieties?.length !== 1 &&
                                <hr className='dropdown-divider' />
                            }
                            <Link className='dropdown-item' to='/profile'>Profile</Link>
                            <Link className='dropdown-item' onClick={() => handleSignOut()}>Logout</Link>
                        </div>
                    </div>
                </div>
                <div className='collapse navbar-collapse' id='sidebarCollapse'>
                    <ul className='navbar-nav'>
                        <li className="nav-item">
                            <Link className='nav-link ' to='/dashboard'>
                                <i className='fe fe-home'></i> Dashboard
                            </Link>
                        </li>
                    </ul>
                    <ul className='navbar-nav'>
                        <li className="nav-item">
                            <a className="nav-link collapsed" href="#buildingDropdown" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="buildingDropdown">
                                <i className="fa fa-building-o"></i>
                                <span className='ms-3'>{societyName}</span>
                            </a>
                            <div className="collapse " id="buildingDropdown">
                                <ul className="nav nav-sm flex-column">
                                    {buildingList?.map((item, index) => (
                                        <li className='nav-item' key={index} >
                                            <Link className='nav-link' to={item.link}>
                                                <i className={item.icon}></i><span className={`${!item.icon.includes('fe') && 'ms-3'}`}>{item.name}</span>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link collapsed" href="#invoiceDropdown" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="invoiceDropdown">
                                <i className="fa fa-money"></i>
                                <span className='ms-3'>Accounting</span>
                            </a>
                            <div className="collapse " id="invoiceDropdown">
                                <ul className="nav nav-sm flex-column">
                                    {entriesList?.map((item, index) => (
                                        <li className='nav-item' key={index} >
                                            <Link className='nav-link' to={item.link}>
                                                <i className={item.icon}></i><span className={`${!item.icon.includes('fe') && 'ms-3'} `}>{item.name}</span>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link collapsed" href="#reportsDropDown" data-bs-toggle="collapse" role="button" aria-expanded="false" aria-controls="reportsDropDown">
                                <i className='fe fe-edit'></i>
                                <span>Reports</span>
                            </a>
                            <div className="collapse " id="reportsDropDown">
                                <ul className="nav nav-sm flex-column">
                                    {reports?.map((item, index) => (
                                        <li className='nav-item' key={index} >
                                            <Link className='nav-link' to={item.link}>
                                                <i className={item.icon}></i><span className={`${!item.icon.includes('fe') && 'ms-3'} `}>{item.name}</span>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </li>
                        {/* <li className="nav-item">
                            <Link className='nav-link ' to='/accounting/reports'>
                                <i className='fe fe-edit'></i> Reports
                            </Link>
                        </li> */}
                    </ul>
                    <ul className='navbar-nav'>
                        <li className="nav-item">
                            <li className='nav-item' >
                                <Link className='nav-link ' to='/visitor'>
                                    <i className="fa fa-user-o"></i><span className='ms-3'>Visitors</span>
                                </Link>
                            </li>
                        </li>
                        <li className="nav-item">
                            <li className='nav-item' >
                                <Link className='nav-link ' to='/complaint'>
                                    <i className="fa fa-newspaper-o"></i><span className='ms-3'>Complaints</span>
                                </Link>
                            </li>
                        </li>
                        <li className="nav-item">
                            <li className='nav-item' >
                                <Link className='nav-link ' to='/facility'>
                                    <i className="fa fa-gear"></i><span className='ms-3'>Facilities</span>
                                </Link>
                            </li>
                        </li>
                        <li className="nav-item">
                            <li className='nav-item' >
                                <Link className='nav-link ' to='/society-staff'>
                                    <i className="fe fe-users"></i><span>Society Staff</span>
                                </Link>
                            </li>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Sidebar
