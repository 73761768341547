import { API, Storage } from 'aws-amplify'
import { Formik } from 'formik'
import { isEmpty } from 'lodash'
import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import swal from 'sweetalert'
import FormControl from '../../../../components/formControl'
import Spinner from '../../../../components/spinner'
import { customSearchResidents } from '../../../../custumQueries/custumQueries'
import { deleteResident, updateResident, updateUser } from '../../../../graphql/mutations'
import Header from '../../../../layouts/header'

function Resident() {
    const residentType = require('../../../../data/residentData.json')
    const approved = require('../../../../data/apporved.json')
    const navigate = useNavigate()
    const society = useSelector((state) => state.user_society)

    const [imageModal, showImageModal] = useState(false)
    const [openImg, setOpenImg] = useState(false)
    const [search, setSearch] = useState('')
    const [nextArr, setNextArr] = useState([])
    const [residents, setResidents] = useState()
    const [spinner, showSpinner] = useState(false)
    const [modal, showModal] = useState(false)
    const [formData, setFormData] = useState({
        name: '',
        id: '',
        userId: '',
        resident_type: '',
        image: '',
        approved: ''
    })


    useEffect(() => {
        if (society) {
            listResident(null, true)
        }
        // eslint-disable-next-line
    }, [society])

    const handleDeleteResident = async (ResidentUser, index) => {
        swal({
            title: `Delete`,
            text: `Are you sure you want to delete Resident?`,
            buttons: ['Cancel', 'Delete'],
            icon: 'warning',
            dangerMode: true,
        }).then((status) => {
            showSpinner(true)
            if (status) {
                API.graphql({
                    query: deleteResident,
                    variables: {
                        input: {
                            id: ResidentUser?.id
                        }
                    }
                }).then(() => {
                    const temp = [...residents]
                    temp.splice(index, 1)
                    setResidents(temp)
                    toast.success('Resident deleted successfully')
                }).catch((err) => toast.error(err.message))
            }
            showSpinner(false)
        }
        )
    }

    const listResident = async (token, prev) => {
        showSpinner(true)
        try {
            API.graphql({
                query: customSearchResidents,
                variables: {
                    filter: { societyId: { eq: society.id } },
                    sort: { direction: 'desc', field: 'createdAt' },
                    limit: 20,
                    nextToken: token || null
                },
            }).then(res => {
                if (!isEmpty(res?.data?.searchResidents?.items)) {
                    showSpinner(true)
                    if (res?.data?.searchResidents?.nextToken !== null && prev) {
                        let newUsers = res?.data?.searchResidents?.nextToken
                        setNextArr((prev) => ([...prev, newUsers]))
                    }
                    setResidents(res?.data?.searchResidents?.items)
                    showSpinner(false)
                }
                showSpinner(false)
            }
            )
        } catch (err) {
            console.log(err)
            showSpinner(false)
        }
    }

    const onNext = () => {
        if (nextArr[nextArr.length - 1] !== null) {
            listResident(nextArr[nextArr.length - 1], true)
        }
    }

    const onPrev = () => {
        if (nextArr.length <= 3) {
            listResident(null, false)
            if (nextArr.length === 3) {
                setNextArr(nextArr.slice(0, -1))
            }
        } else {
            listResident(nextArr[nextArr.length - 3], false)
            setNextArr(nextArr.slice(0, -1))
        }
    }

    const createResident = () => {
        navigate('/resident/create')
    }

    const handleEdit = (form) => {
        setFormData({
            name: form?.user?.name,
            id: form?.id,
            userId: form.user.id,
            resident_type: form.type,
            image: form.user.photo,
            approved: {
                value:
                    form.approved ? 'Approve' : 'Reject',
                label:
                    form.approved ? 'Approve' : 'Reject'
            }
        })
        showModal(true)
    }

    const handleUploadImage = async (e, callback, name) => {
        const file = e.target.files[0]
        const stored = await Storage.put(`buckler-${Math.random().toString(36).substring(2, 15)}.${file.name.split('.')[1]}`, file, { contentType: file.type });
        const url = await Storage.get(stored.key, { level: 'public' })
        let ImageUrl = url.split('?')[0]

        if (ImageUrl) {
            const event = { target: { name: name || '', value: ImageUrl } }
            callback(event)
        }
    }


    const handleEditResidentUser = async (form) => {
        try {
            let updatedUser = await API.graphql({
                query: updateUser,
                variables: {
                    input: {
                        id: formData.userId,
                        photo: form.image,
                        name: form.name
                    }
                }
            })
            let UpdateResidents = await API.graphql({
                query: updateResident,
                variables: {
                    input: {
                        name: form.name,
                        id: form.id,
                        approved: form.approved.value === 'Approve' ? true : false,
                        type: form.resident_type
                    }
                }
            })
            setResidents((prevState) => {
                const index = prevState.findIndex((user) => user.id === form.id);
                prevState[index].approved = UpdateResidents?.data?.updateResident?.approved;
                prevState[index].type = UpdateResidents?.data?.updateResident?.type;
                prevState[index].user = updatedUser.data.updateUser
                return [...prevState];
            });
            showModal(false)
        } catch (err) {
            console.log(err)
        }
    }

    const handelSearchResidente = (e) => {
        if (e.target.value) {
            setSearch(e.target.value)
            API.graphql({
                query: customSearchResidents,
                variables: {
                    filter: { name: { matchPhrasePrefix: e.target.value }, societyId: { eq: society.id } }
                }
            }).then((res) => {
                setResidents(res.data.searchResidents.items)
            })
        } else {
            setSearch('')
            listResident(nextArr[nextArr.length - 2], false)
        }
    }

    const handleImageClick = (image) => {
        setOpenImg(image)
        showImageModal(true)
    }

    return (
        <section>
            <Header title={'Residents'} onclick={createResident} buttonTitle={'Create'} />
            <div className="tab-content">
                <div className="tab-pane fade active show" id="companiesListPane" role="tabpanel" aria-labelledby="companiesListTab">
                    <div className="card" data-list="{&quot;valueNames&quot;: [&quot;item-name&quot;, &quot;item-industry&quot;, &quot;item-location&quot;, &quot;item-owner&quot;, &quot;item-created&quot;], &quot;page&quot;: 10, &quot;pagination&quot;: {&quot;paginationClass&quot;: &quot;list-pagination&quot;}}" id="companiesList">
                        <div className="card-header">
                            <div className="row align-items-center">
                                <div className="col">
                                    <form onSubmit={(event) => { event.preventDefault() }}>
                                        <div className="input-group input-group-flush input-group-merge input-group-reverse">
                                            <input className="form-control list-search" type="search" placeholder="Search" onChange={(e) => handelSearchResidente(e)} />
                                            <span className="input-group-text">
                                                <i className="fe fe-search"></i>
                                            </span>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <Spinner show={spinner}>
                                <table className="table table-hover table-nowrap card-table">
                                    <thead>
                                        <tr>
                                            <th className='text-center'>image</th>
                                            <th className='text-center'>Name</th>
                                            <th className='text-center'>Phone</th>
                                            <th className='text-center'>Building</th>
                                            <th className='text-center'>Unit</th>
                                            <th className='text-center'>User Type</th>
                                            <th className='text-center'>Type</th>
                                            <th className='text-center'>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody className="list fs-base">
                                        {residents?.map((item, index) => (
                                            <tr key={index}>
                                                <td className='text-center'>
                                                    <div className="avatar avatar-xs align-middle me-2">
                                                        <img className="avatar-img rounded-circle" src={item?.user?.photo} alt="..." onClick={() => handleImageClick(item?.user?.photo)} />
                                                    </div>
                                                </td>
                                                <td className='text-center'>
                                                    {item?.user?.name}
                                                </td>
                                                <td className='text-center'>
                                                    {item?.user?.phone}
                                                </td>
                                                <td className='text-center'>
                                                    {item?.building?.name}
                                                </td>
                                                <td className='text-center'>
                                                    {item?.unit?.name}
                                                </td>
                                                <td className='text-center'>
                                                    {
                                                        item?.approved ?
                                                            <span className="badge bg-success-soft">Approved</span>
                                                            :
                                                            <span className="badge bg-danger-soft">Rejected</span>
                                                    }
                                                </td>
                                                <td className='text-center'>{item?.type}</td>
                                                <td className='text-center'>
                                                    <div className='dropdown'>
                                                        <Link className='dropdown-ellipses dropdown-toggle' role='button' data-bs-toggle='dropdown' aria-haspopup='true' aria-expanded='true'>
                                                            <i className='fe fe-more-vertical'></i>
                                                        </Link>
                                                        <div className='dropdown-menu' aria-labelledby='dropdownMenuLink'>
                                                            <div className='dropdown-item cursor-pointer' onClick={() => { handleEdit(item) }}>
                                                                Edit
                                                            </div>
                                                            <div className='dropdown-item cursor-pointer' onClick={() => { handleDeleteResident(item, index) }}>
                                                                Remove user
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </Spinner>
                        </div>
                        <hr className='m-0' />
                        {!search &&
                            <div className='card-footer d-flex justify-content-between'>
                                <ul className='list-pagination-prev pagination pagination-tabs card-pagination'>
                                    <li className='page-item' >
                                        <Link className='page-link ps-0 pe-4 border-end' onClick={onPrev} >
                                            <i className='fe fe-arrow-left me-1'></i> Prev
                                        </Link>
                                    </li>
                                </ul>
                                <ul className='list-pagination-next pagination pagination-tabs card-pagination'>
                                    <li className='page-item'>
                                        <Link className='page-link ps-4 pe-0 border-start' onClick={onNext}>
                                            Next <i className='fe fe-arrow-right ms-1'></i>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        }
                    </div>
                </div>
                <Modal show={imageModal} onHide={() => showImageModal(false)}>
                    <Modal.Body >
                        <img className="avatar-img" src={openImg} alt="..." style={{ 'height': '500px' }} />
                    </Modal.Body>
                </Modal>
                <Modal show={modal} onHide={() => showModal(false)}>
                    <Modal.Body>
                        <h3>Edit Resident</h3>
                        <hr />
                        <Formik initialValues={formData} onSubmit={handleEditResidentUser} validateOnChange={false} validateOnBlur={false} enableReinitialize>
                            {({ handleChange, handleSubmit, values, errors }) => {
                                const resident = values?.resident_type ? residentType?.filter((x) => x?.value === values?.resident_type)[0] : null;
                                return (
                                    <form noValidate onSubmit={handleSubmit}>
                                        <FormControl type='text' name='name' title='Name'
                                            value={values?.name} error={errors.name} onChange={handleChange} />

                                        <FormControl type='select1' title='Select type' name='resident_type' value={resident} options={residentType} error={errors.resident_type} onChange={handleChange} />

                                        <FormControl type='select' title='Select status' name='approved' value={values.approved} options={approved} error={errors.approved} onChange={handleChange} />

                                        <div className='form-group mt-3'>
                                            {values?.image && <img src={values?.image} width={200} alt='' className='preview' />}
                                            <div>
                                                <label>Image</label>
                                                <input name='image' type='file' onChange={(e) => { handleUploadImage(e, handleChange, 'image') }} className='form-control' />
                                            </div>
                                        </div>

                                        <Spinner show={spinner}>
                                            <button className='btn btn-primary' type='submit'>Update</button>
                                            <button className='btn btn-outline-secondary ms-2' type='button' onClick={() => showModal(false)}>Cancel</button>
                                        </Spinner>
                                    </form>
                                )
                            }
                            }
                        </Formik>
                    </Modal.Body>
                </Modal>
            </div>
        </section>
    )
}

export default Resident
