export const custumGetAllSocietyData = /* GraphQL */ `
  query GetSociety($id: ID!) {
    getSociety(id: $id) {
      id
      type
      name
      cityId
      address
      photo
      buildings {
        items {
        id
        name
        societyId
      }
      }
      units {
        items {
        id
        name
        zohoId
        size
        type
      }
      }
      vechicles {
        items {
        id
        name
        number
        type
        societyId
        unitId
      }
      }
      amenities {
        items {
        name
        buildingId
        id
        societyId
      }
      }
      residents {
        items {
        userId
        updatedAt
        unitId
        type
        societyId
        buildingId
        approved
        id
      }
      }
      users {
        items {
        id
        role
        societyId
        user {
          id
          name
          email
          photo
          phone
        }
      }
      }
      createdAt
      updatedAt
    }
  }
`;

export const customGetUnit = /* GraphQL */ `
  query GetUnit($id: ID!) {
    getUnit(id: $id) {
      id
      societyId
      buildingId
      type
      name
      description
      size
       residents {
      items {
        id
        type
        userId
        approved
        user {
          name
          role
          id
          phone
        }
      }
    }
      vechicles {
      items {
        unitId
        type
        societyId
        number
        name
        id
      }
    }
      createdAt
      updatedAt
    }
  }
`;

export const customListSocietyUsers = /* GraphQL */ `
  query ListSocietyUsers(
    $filter: ModelSocietyUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSocietyUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
      id
      role
      user {
        name
        id
      }
    }
      nextToken
    }
  }
`;

export const customUpdateComplaint = /* GraphQL */ `
  mutation UpdateComplaint(
    $input: UpdateComplaintInput!
    $condition: ModelComplaintConditionInput
  ) {
    updateComplaint(input: $input, condition: $condition) {
      id
      residentId
      resident {
        id
        societyId
        buildingId
        unitId
        userId
        type
        approved
        createdAt
        updatedAt
      }
      societyId
      complaintTypeId
      complainType {
        id
        societyId
        name
        priorityType
        SOS
        createdAt
        updatedAt
      }
      description
      assignedTo {
        id
        userId
        societyId
        role
        createdAt
        updatedAt
        user {
        name
        id
        email
      }
      }
      comment
      media
      status
      feedback
      rating
      complaintHistory
      type
      createdAt
      updatedAt
      complaintAssignedToId
    }
  }
`;

export const customSearchUnit = /* GraphQL */ `
  query SearchUnits(
    $filter: SearchableUnitFilterInput
    $sort: [SearchableUnitSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUnitAggregationInput]
  ) {
    searchUnits(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        zohoId
        societyId
        buildingId
        type
        name
        description
        size
        createdAt
        updatedAt
        building {
        name
        id
        societyId
      }
      residents {
      items {
        id
        type
        userId
        approved
        user {
          name
          role
          id
          phone
        }
      }
    }
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;

export const customSearchVisitorEntries = /* GraphQL */ `
  query SearchVisitorEntries(
    $filter: SearchableVisitorEntryFilterInput
    $sort: [SearchableVisitorEntrySortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableVisitorEntryAggregationInput]
  ) {
    searchVisitorEntries(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
          entry
          exit
          visitorEntrySecurityId
          visitorId
          unitId
          type
          updatedAt
          token
          societyId
          id
          media
          notes
          visitor {
            id
            preApproved
            societyId
            type
            validTo
            validFrom
             unit {
          name
          id
          description
          createdAt
          building {
            name
            id
            societyId
          }
        }
            visitor {
              name
              id
              gender
              email
              phone
              photo
            }
          }
        }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;

export const customSearchResidents = /* GraphQL */ `
  query SearchResidents(
    $filter: SearchableResidentFilterInput
    $sort: [SearchableResidentSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableResidentAggregationInput]
  ) {
    searchResidents(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
      approved
      type
      id
      name
      building {
        id
        name
      }
      user {
        name
        id
        email
        phone
        photo
        role
      }
      unit {
        name
        id
        size
        type
      }
    }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }`;


export const customSearchBuildings = /* GraphQL */ `
  query SearchBuildings(
    $filter: SearchableBuildingFilterInput
    $sort: [SearchableBuildingSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableBuildingAggregationInput]
  ) {
    searchBuildings(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        societyId
        name
        createdAt
        updatedAt
         units {
        items {
          id
          name
          societyId
        }
      }
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;

export const customSearchUnits = /* GraphQL */ `
  query SearchUnits(
    $filter: SearchableUnitFilterInput
    $sort: [SearchableUnitSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUnitAggregationInput]
  ) {
    searchUnits(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        zohoId
        societyId
        buildingId
        type
        name
        description
        size
        createdAt
        updatedAt
        building {
        name
        id
      }
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;

export const customSearchSocietyUsers = /* GraphQL */ `
  query SearchSocietyUsers(
    $filter: SearchableSocietyUserFilterInput
    $sort: [SearchableSocietyUserSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableSocietyUserAggregationInput]
  ) {
    searchSocietyUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
      id
      role
      societyId
      updatedAt
      name
      user {
        email
        id
        phone
        photo
        name
      }
    }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;

export const customSearchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: [SearchableUserSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUserAggregationInput]
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
      email
      gender
      id
      name
      societies {
        items {
          id
          role
          societyId
        }
      }
    }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;

export const customSearchResidentUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: [SearchableUserSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUserAggregationInput]
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        name
        email
        phone
        photo
        birthday
        gender
        role
        pin
        createdAt
        updatedAt
        residents {
        items {
          buildingId
          societyId
          unitId
          type
          createdAt
          updatedAt
           unit {
            id
            name
          }
        }
      }
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;

export const customSearchComplaints = /* GraphQL */ `
  query SearchComplaints(
    $filter: SearchableComplaintFilterInput
    $sort: [SearchableComplaintSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableComplaintAggregationInput]
  ) {
    searchComplaints(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
  comment
  complaintAssignedToId
  complaintHistory
  complaintTypeId
  createdAt
  description
  feedback
  id
  media
  rating
  status
  societyId
      assignedTo {
    id
    role
        user {
      name
      phone
      photo
    }
  }
      complainType {
    name
    id
    SOS
  }
      resident {
        unit {
      name
      id
      size
    }
    unitId
    id
    societyId
    type
  }
}
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;

export const customSearchAmenityBookings = /* GraphQL */ `
  query SearchAmenityBookings(
    $filter: SearchableAmenityBookingFilterInput
    $sort: [SearchableAmenityBookingSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableAmenityBookingAggregationInput]
  ) {
    searchAmenityBookings(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
      amenityBookingCreatedByUserId
      amenityId
      amount
      createdAt
      dueDate
      id
      invoiceDate
      invoiceId
      invoiceStatus
      residentId
      slotEndDateTime
      slotStartDateTime
      societyId
      status
      statusHistory
      unitId
      updatedAt
      amenity {
        cost
        createdAt
        description
        free
        id
        media
        name
        slots
        societyId
        updatedAt
      }
      unit {
        id
        name
        size
        type
        building {
          name
          id
          societyId
        }
        residents {
          items {
            name
          }
        }
      }
    }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;